$archon: true;
@use "../../../resources/sass/breakpoints";
@use "../../../resources/sass/variables";

.topContent, .bottomContent {
  padding: 0 variables.$content-padding;
}

.recentArticlesHeader {
  border-bottom: 2px solid variables.$white-600;
  color: variables.$text-white;
  font-size: variables.$small-title-font-size;
  letter-spacing: 0.05em;
  line-height: 1em;
  margin: 0 14px 0 14px;
  padding: variables.$large-gap 0 variables.$small-gap 0;
  text-shadow: 0 4px 11px rgba(0, 0, 0, 0.8);
  margin-bottom: variables.$content-padding;
}

.seeMore {
  align-items: center;
  font-size: variables.$subsubtitle-font-size;
}

.categoryLinks {
  display: flex;
  font-size: variables.$small-title-font-size;
  gap: variables.$large-gap;
  justify-content: center;
  margin: 0 auto;
  max-width: 540px;
  padding: variables.$large-gap variables.$content-padding;
}
