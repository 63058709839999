$archon: true;
@use "../../../resources/sass/breakpoints";
@use "../../../resources/sass/variables";

.navigation {
  margin: 0 variables.$content-padding variables.$content-padding;
  padding: 0 variables.$content-padding variables.$small-gap variables.$content-padding;
  display: flex;
  gap: variables.$xlarge-gap;
  font-size: variables.$subsubtitle-font-size;
  @include breakpoints.md {
    font-size: variables.$small-title-font-size;
  }
  border-bottom: 2px solid variables.$white-600;
}

.option {
  align-items: flex-end;
  color: variables.$text-gray !important;
  display: flex;
  justify-content: flex-end;
  letter-spacing: 1px;
  text-align: center;

  &:hover {
    color: variables.$text-white !important;
  }
}

.selectedOption {
  position: relative;
  cursor: default;
  font-size: variables.$subsubtitle-font-size;
  @include breakpoints.md {
    font-size: variables.$small-title-font-size;
  }

}

.selectionHighlight {
  background-color: variables.$link-blue;
  border-radius: variables.$medium-border-radius;
  bottom: -17px;
  content: '';
  height: 8px;
  left: -25px;
  position: absolute;
  width: calc(100% + 50px);
}

.label {
  display: none;

  @include breakpoints.sm {
    display: initial;
  }
}

.shortenedLabel {
  @include breakpoints.sm {
    display: none;
  }
}
